import React from 'react';
import styled from 'styled-components';
import { langSingleton } from '../lang';
import { Page } from '../components/Page';

const BackgroundImage = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 200px;
  background-image: url(./bottom-bay.png);
  background-size: 50%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-position-x: center;
  @media (max-width: 980px) {
    background-size: 80%;
  }
`;

const Wrapper = styled(Page)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Card = styled.div`
  padding: 20px;
  margin: 20px;
  box-shadow: 2px 2px 16px -8px rgb(0 0 0 / 20%);
  width: 260px;
  height: 340px;
`;

const Picture = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-image: url('${(props) => props.path}');
  background-position: center;
  background-size: cover;
  margin: auto;
  margin-bottom: 20px;
`;

export const Contact = () => {
  return (
    <Wrapper title={langSingleton.getText('contactPageTitle')}>
      <BackgroundImage />
      <Card>
        <Picture path="groom.png" />
        <div>
          <h3>{langSingleton.getText('contactGroomTitle')}</h3>
          <div>
            {langSingleton.getText('contactNameLabel')}: {langSingleton.getText('contactNameAlex')}
          </div>
          <div>
            {langSingleton.getText('contactLanguageLabel')}:{' '}
            <span role="img" aria-label="French flag">
              🇫🇷
            </span>{' '}
            <span role="img" aria-label="British flag">
              🇬🇧
            </span>
          </div>
          <div>{langSingleton.getText('contactPhoneLabel')}: +447943877833</div>
        </div>
      </Card>
      <Card>
        <Picture path="bride.png" />
        <div>
          <h3>{langSingleton.getText('contactBrideTitle')}</h3>
          <div>
            {langSingleton.getText('contactNameLabel')}: {langSingleton.getText('contactNameNina')}
          </div>
          <div>
            {langSingleton.getText('contactLanguageLabel')}:{' '}
            <span role="img" aria-label="Russian flag">
              🇷🇺
            </span>{' '}
            <span role="img" aria-label="German flag">
              🇩🇪
            </span>{' '}
            <span role="img" aria-label="British flag">
              🇬🇧
            </span>
          </div>
          <div>{langSingleton.getText('contactPhoneLabel')}: +4917628105809</div>
        </div>
      </Card>
      <Card>
        <Picture path="violetta.jpg" />
        <div>
          <h3>{langSingleton.getText('contactMoHTitle')}</h3>
          <div>
            {langSingleton.getText('contactNameLabel')}:{' '}
            {langSingleton.getText('contactNameVioletta')}
          </div>
          <div>
            {langSingleton.getText('contactLanguageLabel')}:{' '}
            <span role="img" aria-label="Russian flag">
              🇷🇺
            </span>{' '}
            <span role="img" aria-label="German flag">
              🇩🇪
            </span>{' '}
            <span role="img" aria-label="British flag">
              🇬🇧
            </span>
          </div>
          <div>{langSingleton.getText('contactPhoneLabel')}: +491777440434</div>
        </div>
      </Card>
      <Card>
        <Picture path="mathieu.jpg" />
        <div>
          <h3>{langSingleton.getText('contactBMTitle')}</h3>
          <div>
            {langSingleton.getText('contactNameLabel')}:{' '}
            {langSingleton.getText('contactNameMathieu')}
          </div>
          <div>
            {langSingleton.getText('contactLanguageLabel')}:{' '}
            <span role="img" aria-label="French flag">
              🇫🇷
            </span>{' '}
            <span role="img" aria-label="British flag">
              🇬🇧
            </span>
          </div>
          <div>{langSingleton.getText('contactPhoneLabel')}: +33679952116</div>
        </div>
      </Card>
    </Wrapper>
  );
};
