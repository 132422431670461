import React from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

import { getPeoples, updatePeoples } from '../api/attendance';
import { Steps, message } from 'antd';
import { AttendanceNameCode } from '../components/attendance-name-code';
import { AttendanceStepForm } from '../components/attendance-step-form';
import styled from 'styled-components';
import { langSingleton } from '../lang';
import { Page } from '../components/Page';

const { Step } = Steps;

const TopRightImage = styled.div`
  width: 300px;
  height: 300px;
  position: fixed;
  right: 0px;
  top: 0px;
  background-image: url('./bouquet-tr.png');
  background-size: cover;
  background-position-y: -30px;
  background-repeat: no-repeat;
  background-position-x: 30px;
`;

const BottomLeftImage = styled.div`
  width: 215px;
  height: 300px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  background-image: url('./bouquet-bl.png');
  background-size: cover;
  background-position-y: 38px;
  background-repeat: no-repeat;
  background-position-x: -18px;
`;

const StyledPage = styled(Page)`
  display: flex;
`;

export class Attendance extends React.Component {
  state = {
    invitation: null,
    currentStep: 0,
    status: 'pending',
  };

  onFinish = (values) => {
    getPeoples(values.code).then((data) => {
      this.setState({
        invitation: data,
      });
    });
  };

  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }

  next = () => {
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  };

  prev = () => {
    const currentStep = this.state.currentStep - 1;
    this.setState({ currentStep });
  };

  onCompleteStep = (values) => {
    const { currentStep, invitation } = this.state;

    const newInvitation = invitation.map((invite, i) => {
      if (currentStep === i) {
        return {
          ...invite,
          accepted: values.come === undefined ? 'true' : 'false',
          email: values.email || '',
          name: `${values.firstname} ${values.lastname}`,
          diet: values.diet || '',
          allergies: Array.isArray(values.allergies) ? values.allergies.join(',') : '',
          stay: values.stay === true ? 'true' : 'false',
          song: values.song,
          comingsunday: !!values.sundayStay ? 'true' : 'false',
        };
      }

      return invite;
    });

    if (currentStep === invitation.length - 1) {
      this.onComplete(newInvitation);
    }

    this.setState({
      invitation: newInvitation,
      currentStep: currentStep + 1,
    });
  };

  onComplete = (invitation) => {
    updatePeoples(invitation)
      .then((res) => {
        if (res.message && res.message.error) {
          message.error(langSingleton.getText('registrationFailedMessage'));
          this.setState({
            status: 'failed',
          });
        } else {
          message.success(langSingleton.getText('registrationSuccessMessage'), 5);
          this.setState({
            status: 'success',
          });
        }
      })
      .catch(() => {
        message.error(langSingleton.getText('registrationFailedMessage'));
        this.setState({
          status: 'failed',
        });
      });
  };

  onFailedCompleteStep = (errorInfo) => {
    console.error(errorInfo);
  };

  getTitle(invite) {
    if (!invite.name) {
      return 'Guest';
    }

    const res = invite.name.split(' ');
    return res[0] || 'Guest';
  }

  render() {
    const { currentStep, invitation, status } = this.state;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const span = vw > 980 ? 14 : 24;
    const offset = vw > 980 ? 5 : 0;

    return (
      <StyledPage title={langSingleton.getText('attendancePageTitle')}>
        <Row style={{ flex: 1, position: 'relative' }} align="middle" justify="center">
          <TopRightImage />
          <BottomLeftImage />
          {!invitation && (
            <AttendanceNameCode onFinish={this.onFinish} onFinishFailed={this.onFinishFailed} />
          )}

          {Array.isArray(invitation) && (
            <Col
              span={span}
              offet={offset}
              style={{
                padding: 20,
                backgroundColor: 'white',
                borderRadius: 5,
                boxShadow: '2px 2px 16px -8px rgb(0 0 0 / 20%)',
              }}
            >
              <div style={{ marginBottom: 40 }}>
                <h3>
                  {langSingleton.getText('attendanceGreeting')} {invitation[0].lead}
                </h3>
              </div>
              {currentStep < invitation.length && (
                <Steps current={currentStep}>
                  {invitation.map((item) => (
                    <Step key={item.id} title={this.getTitle(item)} />
                  ))}
                </Steps>
              )}

              <div className="steps-content" style={{ marginTop: 40 }}>
                {currentStep === invitation.length && status === 'success' && (
                  <div>
                    <div style={{ fontSize: 26, marginBottom: 10 }}>
                      {langSingleton.getText('attendanceSuccessTitle')}
                    </div>
                    <div>
                      {this.state.invitation.every((invite) => invite.accepted === 'false')
                        ? langSingleton.getText('attendanceNotComeDescription')
                        : langSingleton.getText('attendanceSuccessDescription')}
                    </div>
                    <Link to="/blog">
                      <Button style={{ marginTop: 30, marginBottom: 20 }} type="primary">
                        {langSingleton.getText('attendanceSuccessButton')}
                      </Button>
                    </Link>
                  </div>
                )}
                {currentStep === invitation.length && status === 'failed' && (
                  <div>
                    <div style={{ fontSize: 26, marginBottom: 10 }}>
                      {langSingleton.getText('attendanceFailedTitle')}
                    </div>
                    <div>{langSingleton.getText('attendanceFailedDescription')}</div>
                    <Link to="/contact">
                      <Button style={{ marginTop: 30, marginBottom: 20 }} type="primary">
                        {langSingleton.getText('attendanceFailedButton')}
                      </Button>
                    </Link>
                  </div>
                )}
                {invitation.map((invite, index) => {
                  return (
                    <AttendanceStepForm
                      key={index}
                      invitation={invite}
                      active={index === currentStep}
                      first={index === 0}
                      last={index === invitation.length - 1}
                      onFinish={this.onCompleteStep}
                      onFinishFailed={this.onFailedCompleteStep}
                      onPrev={this.prev}
                    />
                  );
                })}
              </div>
            </Col>
          )}
        </Row>
      </StyledPage>
    );
  }
}
