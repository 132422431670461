import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 80px 110px;
  @media (max-width: 980px) {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 60px;
  }
`;

const TitleContainer = styled.div`
  margin: 26px 0px;
  line-height: 1;
  @media (max-width: 980px) {
    margin: 0px;
  }
`;

export const Page = ({ children, title, className }) => {
  return (
    <Container>
      <TitleContainer>
        <h2>{title}</h2>
      </TitleContainer>
      <div style={{ flex: 1 }} className={className}>
        {children}
      </div>
    </Container>
  );
};
