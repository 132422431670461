import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Modal } from 'antd';
import { langSingleton } from '../lang';
import { DownOutlined } from '@ant-design/icons';

const emojiMap = {
  fr: '🇫🇷',
  ru: '🇷🇺',
  en: '🇬🇧',
};

const styles = {
  item: {
    margin: '10px 16px',
    fontSize: 15,
  },
};

const HeaderWrapper = styled.ul`
  margin: 20px 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  @media (max-width: 980px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 30px;
  z-index: 99;
  @media (min-width: 980px) {
    display: none;
  }
`;

const LangItem = styled(Menu.Item)`
  cursor: pointer;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const LangItemModal = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

const selectedStyle = (color) => ({
  borderBottom: `2px solid ${color}`,
  fontWeight: 500,
});

const navItems = [
  {
    to: '/',
    titleId: 'menuHome',
  },
  {
    to: '/schedule',
    titleId: 'menuSchedule',
  },
  {
    to: '/discover',
    titleId: 'menuVenue',
  },
  {
    to: '/attendance',
    titleId: 'menuAttendance',
  },
  {
    to: '/blog',
    titleId: 'menuBlog',
  },
  {
    to: '/gift',
    titleId: 'menuGift',
  },
  {
    to: '/contact',
    titleId: 'menuContact',
  },
];

const DesktopMenu = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const color = isLandingPage ? 'white' : '#515B53';

  return (
    <HeaderWrapper>
      {navItems.map(({ to, titleId }) => (
        <li
          key={to}
          style={{
            ...styles.item,
            ...(location.pathname === to && selectedStyle(color)),
          }}
        >
          <Link to={to} style={{ color: color }}>
            {langSingleton.getText(titleId)}
          </Link>
        </li>
      ))}
    </HeaderWrapper>
  );
};

const MobileMenu = () => {
  const location = useLocation();
  const currentItem = navItems.find(({ to }) => location.pathname.includes(to)).title;

  const menu = (
    <Menu>
      {navItems.map(({ to, titleId }) => (
        <Menu.Item key={to}>
          <Link to={to}>{langSingleton.getText(titleId)}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <MobileWrapper>
      <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
        <div style={{ display: 'flex' }}>
          <MenuOutlined
            size={120}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 20,
              marginRight: 10,
              cursor: 'pointer',
              color: location.pathname === '/' ? 'white' : 'inherit',
            }}
          />
          <div>{currentItem}</div>
        </div>
      </Dropdown>
    </MobileWrapper>
  );
};

export const Header = () => {
  const lang = langSingleton.getLang();
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const setLangWrapper = (newLang) => {
    langSingleton.setLang(newLang);
    window.location.reload();
  };

  return (
    <div>
      <DesktopMenu setLangWrapper={setLangWrapper} />
      <MobileMenu setLangWrapper={setLangWrapper} />
      <Modal title="Chose a language" visible={!lang} width={300}>
        <LangItemModal onClick={() => setLangWrapper('fr')}>
          <span role="img" aria-label="French flag">
            🇫🇷
          </span>{' '}
          Francais
        </LangItemModal>
        <LangItemModal onClick={() => setLangWrapper('en')}>
          <span role="img" aria-label="English flag">
            🇬🇧
          </span>{' '}
          English
        </LangItemModal>
        <LangItemModal onClick={() => setLangWrapper('ru')}>
          <span role="img" aria-label="Russian flag">
            🇷🇺
          </span>{' '}
          Русский
        </LangItemModal>
      </Modal>
      {lang && (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <LangItem key="fr" onClick={() => setLangWrapper('fr')}>
                <span role="img" aria-label="French flag">
                  🇫🇷
                </span>{' '}
                Francais
              </LangItem>
              <LangItem key="en" onClick={() => setLangWrapper('en')}>
                <span role="img" aria-label="English flag">
                  🇬🇧
                </span>{' '}
                English
              </LangItem>
              <LangItem key="ru" onClick={() => setLangWrapper('ru')}>
                <span role="img" aria-label="Russian flag">
                  🇷🇺
                </span>{' '}
                Русский
              </LangItem>
            </Menu>
          }
        >
          <div
            style={{
              cursor: 'pointer',
              width: 30,
              height: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 4,
              marginTop: 26,
              position: 'absolute',
              right: 30,
              zIndex: 99,
            }}
          >
            {emojiMap[lang]}
            <DownOutlined style={{ marginLeft: 6, color: isLandingPage ? 'white' : 'inherit' }} />
          </div>
        </Dropdown>
      )}
    </div>
  );
};
