const texts = {
  fr: {
    menuHome: 'Accueil',
    menuSchedule: 'Planning',
    menuVenue: 'Découvrir',
    menuAttendance: 'RSVP',
    menuBlog: 'Blog',
    menuContact: 'Contact',
    menuGift: 'Cadeau',
    venueTitle: 'Découvrir',
    contactPageTitle: 'Contact',
    codePlaceholder: 'code',
    registrationSuccessMessage: 'Votre enregistrement a été effectué avec succes',
    registrationFailedMessage: "Nous n'avons pas pu confirmer votre enregistrement",
    codeRequiredMessage: "Veuillez entrer un code d'invitation",
    allergiesPlaceholder: 'Veuillez entrer vos allergies',
    landingDate: '2 Juillet 2022',
    landingAttendanceButton: 'Confirmer sa presence',
    scheduleMainTitle: 'Planning',
    scheduleSaturdayTitle: 'Samedi 2 Juillet',
    scheduleSundayTitle: 'Dimanche 3 Juillet',
    scheduleSaturdayActivity1: 'Arrivée des invités',
    scheduleSaturdayActivity2: 'Cérémonie laïque',
    scheduleSaturdayActivity3: "Vin d'honneur",
    scheduleSaturdayActivity4: 'Gateau des mariés',
    scheduleSaturdayActivity5: 'Dîner',
    scheduleSaturdayActivity6: 'Première danse puis soirée',
    scheduleSundayActivity1: 'Petit dejeunée pour les lève tôt',
    scheduleSundayActivity2: 'Brunch',
    scheduleSundayActivity3: 'Jeux et ouverture de la piscine',
    discoverTitle: 'Endroits utiles a decouvrir',
    discoverDescription:
      '<p style="text-align: justify;">Nous avons préparé une collection de lieux utiles et étonnants à visiter dans la région.</p> <p style="text-align: justify;"> Tout est très facile à localiser sur cette carte interactive - vous pouvez filtrer, zoomer et bien sûr déplacer la carte. </p> <p style="text-align: justify;"> Pour plus de détails, consultez notre <a href="https://nina-alex.com/blog"><strong>blog</strong< /a>.</p>.',
    closeLabel: 'Confirmer votre venue avant le',
    attendancePageTitle: 'Enregistrer',
    attendanceMainTitle: "Entrez votre code d'invitation",
    attendanceMainButton: 'Valider',
    attendanceGreeting: 'Salut',
    attendanceAdultLabel: 'Adulte',
    attendanceChildLabel: 'Enfant',
    attendanceFormFirstname: 'Prénom',
    attendanceFormLastname: 'Nom',
    attendanceFormEmail: 'Email',
    attendanceFormDiet: "Alimentation d'invité",
    attendanceFormDietOption1: 'Végétarien',
    attendanceFormDietOption2: 'Mange de tout',
    attendanceFormDietOption3: 'Végan',
    attendanceFormDietOption4: 'Autre',
    attendanceFormAllergy: "Est-ce que l'invité a une allergie alimentaire?",
    attendanceFormAllergyOption1: 'Noix',
    attendanceFormAllergyOption2: 'Poisson ou fruits de mer',
    attendanceFormAllergyOption3: 'Lactose',
    attendanceFormAllergyOption4: 'Gluten',
    attendanceFormAllergyOption5: 'Autre',
    attendanceFormAllergyOption6: 'Aucune allergie',
    attendanceFormSong: 'Quel son te fera entrer le dancefloor ?',
    attendanceFormStay:
      "Est-ce que l'invité aura besoin d'aide pour organiser la reservation d'une chambre?",
    attendanceFormCantJoin: 'La personne ne pourra pas venir',
    attendanceFormPrevious: 'Précédent',
    attendanceFormNext: 'Valider et Suivant',
    attendanceFormComplete: 'Completer',
    attendanceFormSunday:
      'Vous êtes un invité spécial et nous serions ravis de vous avoir avec nous pour un autre tour le dimanche 3 Juillet. Souhaitez vous vous joindre à nous pour un brunch et des jeux avec piscine',
    attendanceSuccessTitle: "Merci, l'enregistrement a ete pris en compte.",
    attendanceSuccessDescription: 'Nous avons hate de celebrer avec vous ce moment special!',
    attendanceNotComeDescription:
      "Nous sommes triste que vous ne puissiez pas venir et esperons avoir l'occasion de se voir au plus vite.",
    attendanceSuccessButton: 'Visiter notre blog',
    attendanceFailedTitle: "Oh non, nous n'avons pas pu completer l'enregistrement.",
    attendanceFailedDescription: "Veuillez contacter Nina ou Alex pour faire l'enregistrement.",
    attendanceFailedButton: 'Contacter',
    giftPageTitle: 'Cadeau',
    giftTitle: 'Cadeau de mariage',
    giftDescription:
      '<p style="text-align: justify;">Célébrer avec vous le jour de notre mariage est le plus beau cadeau que nous pouvons demander! Si vous voulez vraiment nous donner quelque chose, une contribution à notre fonds de lune de miel de rêve serait très appréciée. </p> <p style="text-align: justify;">Depuis plusieurs années maintenant, nous nous imaginons dans un grand parc national en train de regarder les Big Five en safari ! Nous continuerons ensuite notre voyage vers l\'une des plages de sable blanc où nous siroterons des cocktails. Nous emmènerons tous nos invités dans le voyage et partagerons notre expérience unique avec vous. Tous les contributeurs peuvent également s\'attendre à une petite surprise ! </p> <p style="text-align: justify;"> Vous pourrez laissez le cadeau le jour même du mariage dans un espace cadeau dédié.',
    giftButton: 'Contibuer avec Paypal',
    contactBrideTitle: 'La Mariée',
    contactGroomTitle: 'Le Marié',
    contactMoHTitle: "Demoiselle d'honneur",
    contactBMTitle: "Garcon d'honneur",
    contactNameLabel: 'Nom',
    contactNameAlex: 'Alex',
    contactNameMathieu: 'Mathieu',
    contactNameNina: 'Nina',
    contactNameVioletta: 'Violetta',
    contactLanguageLabel: 'Langue',
    contactPhoneLabel: 'Téléphone',
    domaineIconLabel: 'Domaine',
    stayIconLabel: 'Hotel et Airbnb',
    sightIconLabel: 'A visiter',
    airportIconLabel: 'Aeroport',
    seatingTitle: 'Trouver sa place',
  },
  ru: {
    menuHome: 'Главная',
    menuSchedule: 'Программа',
    menuVenue: ' Карта',
    menuAttendance: 'Я буду!',
    menuBlog: 'Блог',
    menuContact: 'Контакты',
    menuGift: 'Подарки',
    venueTitle: 'Полезное и интересное',
    contactPageTitle: 'Контакты',
    allergiesPlaceholder: 'Выбери из списка если есть аллергии',
    registrationSuccessMessage: 'Спасибо за регистрацию.',
    registrationFailedMessage: 'Не удалось зарегистрироваться, попробуй ещё раз или свяжись с нами',
    codePlaceholder: 'код',
    codeRequiredMessage: 'Пожалуйста введи код приглашения',
    landingDate: '2 июля 2022',
    landingAttendanceButton: 'Ты придёшь?',
    scheduleMainTitle: 'Программа',
    scheduleSaturdayTitle: 'Суббота, 2 июля',
    scheduleSundayTitle: 'Воскресенье, 3 июля',
    scheduleSaturdayActivity1: 'Прибытие гостей',
    scheduleSaturdayActivity2: 'Церемония',
    scheduleSaturdayActivity3: 'Прием',
    scheduleSaturdayActivity4: 'Торт и кофе',
    scheduleSaturdayActivity5: 'Ужин',
    scheduleSaturdayActivity6: 'Первый танец и вечеринка',
    scheduleSundayActivity1: 'Завтрак для жаворонок',
    scheduleSundayActivity2: 'Бранч',
    scheduleSundayActivity3: 'Игры и открытие бассейна',
    discoverTitle: 'Endroits utiles a decouvrir',
    discoverDescription:
      '<p style="text-align: justify;">Мы подготовили коллекцию не только полезных, но и поистине удивительных мест, которые стоит посетить в этой части Франции.</p> <p style="text-align: justify;"> На этой интерактивной карте все очень легко найти - ты можешь выбрать фильтр, увеличивать и уменьшать масштаб и, конечно же, перемещать карту. Веселись, играя с картой!</p> <p style="text-align: justify;">Для более подробных рекомендаций, посети наш <a href="https://nina-alex.com/blog"><strong>блог</strong</a>.</p>',
    closeLabel: 'Мы ждём ответа до',
    attendancePageTitle: 'Ты придёшь?',
    attendanceMainTitle: 'Введи код приглашения',
    attendanceMainButton: 'Подтвердить',
    attendanceGreeting: 'Привет',
    attendanceAdultLabel: 'Взрослый',
    attendanceChildLabel: 'Ребёнок',
    attendanceFormFirstname: 'Имя',
    attendanceFormLastname: 'Фамилия',
    attendanceFormEmail: 'Электронная почта',
    attendanceFormDiet: 'Диета',
    attendanceFormDietOption1: 'Вегетарианец',
    attendanceFormDietOption2: 'Я ем всё',
    attendanceFormDietOption3: 'Веган',
    attendanceFormDietOption4: 'Другое',
    attendanceFormAllergy: 'Пищевые аллергии',
    attendanceFormAllergyOption1: 'Орехи',
    attendanceFormAllergyOption2: 'Рыба или морепродукты',
    attendanceFormAllergyOption3: 'Лактоза',
    attendanceFormAllergyOption4: 'Глютен',
    attendanceFormAllergyOption5: 'Другое',
    attendanceFormAllergyOption6: 'Нет аллергий',
    attendanceFormSong: 'Под какую песню ты зажгёшь на танцполе?',
    attendanceFormStay:
      'Потребуется ли этому гостю помощь в организации и бронировании проживания?',
    attendanceFormCantJoin: 'Приглашенный не cможет прийти',
    attendanceFormPrevious: 'Предыдущий гость',
    attendanceFormNext: 'Подтвердить и cледующий гость',
    attendanceFormComplete: 'Завершить',
    attendanceFormSunday:
      'Мы будем очень рады если ты останешься с нами по-дольше на блинчики, игры и бассейн. Я буду и в воскресение!',
    attendanceSuccessTitle: 'Спасибо за регистрацию!',
    attendanceNotComeDescription:
      'Нам грустно, что ты не сможешь приехать, мы надеемся увидеть тебя в ближайшее время.',
    attendanceSuccessDescription:
      'Мы с нетерпением ждем, когда мы будем вместе праздновать этот особенный день!',
    attendanceSuccessButton: 'Посети наш блог',
    attendanceFailedTitle: 'Что-то пошло не так при регистрации.',
    attendanceFailedDescription: 'Пожалуйста свяжись с Ниной или Алексом.',
    attendanceFailedButton: 'Контакты',
    giftPageTitle: 'Подарки',
    giftTitle: 'Подарки',
    giftDescription:
      '<p style="text-align: justify;">Отпраздновать с вами день нашей свадьбы - лучший подарок, который мы могли бы пожелать! Если тебе прям очень сильно хочется что-то нам подарить, мы будем очень благодарны за вклад в медовый месяц нашей мечты. </p> <p style="text-align: justify;"> Вот уже несколько лет мы представляем себя в большом национальном парке, наблюдая за большой африканской пятеркой во время сафари. Затем мы продолжим наше путешествие на один из белых песчаных пляжей, где мы будем потягивать коктейли и беседовать о  воспиминаниях о нашем празднике и о всех вас. Мы возьмем всех наших гостей в путешествие и поделимся с вами нашим уникальным опытом. Всех участников также ждут небольшие сюрпризы! </p> <p style="text-align: justify;"> Вот как ты можешь нам что-то подарить: В любое время до и несколько дней после свадьбы. Или оставь ваш подарок в день свадьбы в специальной зоне для подарков.',
    giftButton: 'онлайн перевод на PayPal',
    contactBrideTitle: 'Невеста',
    contactGroomTitle: 'Жених',
    contactMoHTitle: 'Подружка невесты',
    contactBMTitle: 'Шафер',
    contactNameLabel: 'Имя',
    contactNameAlex: 'Алекс',
    contactNameMathieu: 'Матьё',
    contactNameNina: 'Нина',
    contactNameVioletta: 'Виолетта',
    contactLanguageLabel: 'Язык',
    contactPhoneLabel: 'Номер телефона',
    domaineIconLabel: 'Место',
    stayIconLabel: 'Проживание',
    sightIconLabel: 'Достопримечательность',
    airportIconLabel: 'Аэропорт',
    seatingTitle: 'Trouver sa place',
  },
  en: {
    menuHome: 'Main',
    menuSchedule: 'Our Day',
    menuVenue: 'Discover',
    menuAttendance: 'RSVP',
    menuBlog: 'Blog',
    menuContact: 'Contact',
    menuGift: 'Gift',
    venueTitle: 'Discover',
    contactPageTitle: 'Contact',
    codePlaceholder: 'code',
    codeRequiredMessage: 'Please enter your invitation code',
    allergiesPlaceholder: 'Please enter your allergies',
    registrationSuccessMessage: 'The registration was successfull, thank you!',
    registrationFailedMessage: 'Failed registering, please try again or contact us.',
    landingDate: '2 July 2022',
    landingAttendanceButton: "I'll be there!",
    scheduleMainTitle: 'Planning',
    scheduleSaturdayTitle: 'Saturday, 2 July',
    scheduleSundayTitle: 'Sunday, 3 July',
    scheduleSaturdayActivity1: "Guests' arrival",
    scheduleSaturdayActivity2: 'Ceremony',
    scheduleSaturdayActivity3: 'Reception',
    scheduleSaturdayActivity4: 'Cake and Champaign',
    scheduleSaturdayActivity5: 'Dinner',
    scheduleSaturdayActivity6: 'First dance and party',
    scheduleSundayActivity1: 'Small breakfast for early birds',
    scheduleSundayActivity2: 'Brunch',
    scheduleSundayActivity3: 'Games and swimming pool',
    discoverTitle: 'Useful and interesting places',
    discoverDescription:
      '<p style="text-align: justify;">We prepared a collection of useful places but also truly astonishing locations to visit in the area.</p> <p style="text-align: justify;"> Everything is very easy to locate on this interactive map - you can filter, zoom in and out and of course move the map around. Have fun playing around! </p> <p style="text-align: justify;"> For more details, check out our <a href="https://nina-alex.com/blog"><strong>blog</strong</a>.</p>.',
    closeLabel: 'Confirm attendance by',
    attendancePageTitle: 'Will you celebrate with us?',
    attendanceMainTitle: 'Enter your invitation code',
    attendanceMainButton: 'Confirm',
    attendanceGreeting: 'Hi',
    attendanceAdultLabel: 'Adult',
    attendanceChildLabel: 'Child',
    attendanceFormFirstname: 'Firstname',
    attendanceFormLastname: 'Lastname',
    attendanceFormEmail: 'Email',
    attendanceFormDiet: "Guest's diet",
    attendanceFormDietOption1: 'Vegetarian',
    attendanceFormDietOption2: 'I eat everything',
    attendanceFormDietOption3: 'Vegan',
    attendanceFormDietOption4: 'Other',
    attendanceFormAllergy: "Guest's food allergies?",
    attendanceFormAllergyOption1: 'Nuts',
    attendanceFormAllergyOption2: 'Fish or seafood',
    attendanceFormAllergyOption3: 'Lactose',
    attendanceFormAllergyOption4: 'Gluten',
    attendanceFormAllergyOption5: 'Other',
    attendanceFormAllergyOption6: 'No allergies',
    attendanceFormSong: 'Hearing which song will make you hit the dance floor?',
    attendanceFormStay: 'Would this guest need help to organise a stay?',
    attendanceFormCantJoin: "The invited person can't come",
    attendanceFormPrevious: 'Previous guest',
    attendanceFormNext: 'Confirm and next guest',
    attendanceFormComplete: 'Complete',
    attendanceFormSunday:
      'You are a special guest and we would love to have you with us for another round on Sunday. Would you like to join us for a Sunday crepe brunch, games and swimming pool?',
    attendanceSuccessTitle: 'Thank you for registering.',
    attendanceNotComeDescription: "We are sad that you can't make it, we hope to see you soon.",
    attendanceSuccessDescription:
      'We look forward to having you celebrating with us on this special day!',
    attendanceSuccessButton: 'Visit our blog',
    attendanceFailedTitle: 'Something went wrong while registering.',
    attendanceFailedDescription: 'Please contact Nina or Alex.',
    attendanceFailedButton: 'Contact',
    giftPageTitle: 'Gift',
    giftTitle: 'A travel close to our heart',
    giftDescription:
      '<p style="text-align: justify;">Celebrating with you on our wedding day is the greatest gift we could ask for! If you really want to give us something, a contribution to our dream honeymoon fund would be most appreciated. </p> <p style="text-align: justify;">For several years now, we are imagining ourselves in a big national park watching the big five on a safari! Then continue our journey to one of the white sandy beaches where we will sip cocktails and go over the memories from our celebration with you.  We will take all our guests on the journey and share our unique experience with you. All contributors can also expect a little surprise! </p> <p style="text-align: justify;"> You will be able to leave the gift on the wedding day itself in a dedicated gift area.',
    giftButton: 'Contribute with Paypal',
    contactBrideTitle: 'Bride',
    contactGroomTitle: 'Groom',
    contactMoHTitle: 'Maid of Honor',
    contactBMTitle: 'Best Man',
    contactNameLabel: 'Name',
    contactNameAlex: 'Alex',
    contactNameMathieu: 'Mathieu',
    contactNameNina: 'Nina',
    contactNameVioletta: 'Violetta',
    contactLanguageLabel: 'Language',
    contactPhoneLabel: 'Phone',
    domaineIconLabel: 'Domaine',
    stayIconLabel: 'Overnight stay',
    sightIconLabel: 'Sightseeing',
    airportIconLabel: 'Airport',
    seatingTitle: 'Find your seat',
  },
};

class Language {
  lang = null;

  constructor() {
    this.lang = localStorage.getItem('lang');
  }

  getOptions() {
    return Object.keys(texts);
  }

  getLang() {
    return this.lang;
  }

  setLang(lang) {
    localStorage.setItem('lang', lang);
  }

  getText(id) {
    if (!this.lang) {
      return null;
    }

    return texts[this.lang][id];
  }
}

export const langSingleton = new Language();
