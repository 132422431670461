export const getPeoples = (code) => {
  const payload = {};

  if (code) {
    payload.code = code;
  }

  const params = new URLSearchParams(payload);

  return fetch(`${process.env.REACT_APP_API_URL}/invitation-v2?${params.toString()}`)
    .then((res) => res.json())
    .then(({ data }) => data);
};

export const updatePeoples = (invitation) => {
  return fetch(`${process.env.REACT_APP_API_URL}/update-invitation-v2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(invitation),
  }).then((res) => res.json());
};
