import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { langSingleton } from '../lang';
import { Page } from '../components/Page';

const Container = styled(Page)`
  max-width: 1000px;
  margin-bottom: 140px;
  border-radius: 5px;

  font-size: 16px;

  @media (max-width: 980px) {
    padding: 20px 0px;
    padding-top: 70px;

    h1 {
      font-size: 40px !important;
    }
  }

  h1 {
    font-family: sans-serif;
    font-size: 46px;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 26px;
    margin-top: 20px;
  }
`;

export const BlogFull = () => {
  const [article, setArticle] = useState({});
  const { id, lang } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get-blog?id=${id}&lang=${lang}`)
      .then((res) => res.json())
      .then((payload) => setArticle(payload.data));
  }, [setArticle, id, lang]);

  return (
    <Container title={langSingleton.getText('menuBlog')}>
      <Breadcrumb style={{ marginBottom: 20 }}>
        <Breadcrumb.Item>
          <Link to="/blog">{langSingleton.getText('menuBlog')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{article.title}</Breadcrumb.Item>
      </Breadcrumb>
      {article.content && <div dangerouslySetInnerHTML={{ __html: article.content }} />}
    </Container>
  );
};
