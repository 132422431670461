import React from 'react';
import styled from 'styled-components';
import { langSingleton } from '../lang';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 140px;
  width: 100%;

  @media (max-width: 980px) {
    padding: 0px;
    justify-content: flex-end;
  }
`;

const ContentSubWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;

  @media (max-width: 980px) {
    padding-left: 40px;
  }

  @media (max-width: 600px) {
    padding: 30px;
    flex: inherit;
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(0, 0, 0, 0.5788690476190477) 100%
    );
  }
`;

const BackgroundPicture = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./landing_9.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  flex: 1;

  @media (max-width: 600px) {
    background-position-y: 0px;
    background-position-x: calc(50% - 210px);
    background-color: black;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    133deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.1) 36%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (max-width: 600px) {
    width: 100%;
    background: none;
  }
`;

const DateTitle = styled.h3`
  color: white !important;
  font-family: bimash_palace_script_mtRg;
  font-size: 66px;
  margin-top: -40px;

  @media (max-width: 980px) {
    font-size: 48px;
  }
`;

const LandingButton = styled(Button)`
  font-family: sans-serif;
  background-color: white;
  border-color: white;
  color: #333;
  height: 40px;

  @media (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    background-color: white;
    color: #515b53;
  }
`;

export const Landing = () => {
  return (
    <div
      style={{
        flex: 1,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        position: 'relative',
      }}
    >
      <BackgroundPicture />
      <Overlay />
      <ContentWrapper>
        {/* <Overlay /> */}
        <ContentSubWrapper>
          <h1 style={{ color: 'white', marginBottom: 0 }}>Nina & Alex</h1>
          <DateTitle>{langSingleton.getText('landingDate')}</DateTitle>
          <Link to="/attendance" style={{ width: '100%' }}>
            <LandingButton type="primary">
              {langSingleton.getText('landingAttendanceButton')}
            </LandingButton>
          </Link>
        </ContentSubWrapper>
      </ContentWrapper>
    </div>
  );
};
