import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { langSingleton } from '../lang';

export const BlogRedirect = () => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const currentLang = langSingleton.getLang();

    fetch(`${process.env.REACT_APP_API_URL}/get-blog?id=${id}&lang=${currentLang}`)
      .then((res) => res.json())
      .then((payload) => {
        if (!payload.data) {
          history.push(`/blog/${id}/en${history.location.hash}`);
        } else {
          history.push(`/blog/${id}/${currentLang}${history.location.hash}`);
        }
      });
  }, [id, history]);

  return null;
};
