import React from 'react';
import { Page } from '../components/Page';
import { langSingleton } from '../lang';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }
`;

const StyledTitle = styled.h3`
  @media (max-width: 980px) {
    margin-top: 20px;
  }
`;

export class Gift extends React.Component {
  render() {
    return (
      <Page title={langSingleton.getText('giftPageTitle')}>
        <Wrapper>
          <div styled={{ flex: 1 }}>
            <StyledTitle>{langSingleton.getText('giftTitle')}</StyledTitle>
            <p
              style={{ fontSize: 16, maxWidth: 580, fontWeight: 200, marginRight: 40 }}
              dangerouslySetInnerHTML={{ __html: langSingleton.getText('giftDescription') }}
            />
          </div>
          <div styled={{ flex: 1 }}>
            <img src="./landing_10.jpeg" alt="Us gift" width="100%" style={{ maxWidth: 580 }} />
          </div>
        </Wrapper>
      </Page>
    );
  }
}
