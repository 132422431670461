import React, { useState } from 'react';
import styled from 'styled-components';
import ReactMapboxGl, { Layer, Feature, Popup } from 'react-mapbox-gl';
import venues from '../venues.json';
import { groupBy } from '../utils/groupBy';
import { Checkbox } from 'antd';
import { langSingleton } from '../lang';
import { useHistory } from 'react-router-dom';
import { Page } from '../components/Page';

const CheckboxGroup = Checkbox.Group;

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiYWxleDMxNjUiLCJhIjoiY2psZXViZnIyMG5xdDNqbmpiejcydTE4MSJ9.Feyg1TyWWRs6nclrYcYTJA',
});

const center = [2.3169568, 43.8853371];
const zoom = [6];

const offset = [0, -15];

const styles = {
  container: {
    maxWidth: 200,
    minWidth: 120,
    borderRadius: 5,
  },
  image: {
    margin: 'auto',
    maxWidth: '100%',
  },
  footer: {
    padding: '8px 12px',
  },
};

const MapWrapper = styled.div`
  z-index: 999;
  width: 100%;
  height: 500px;
`;

const Image = styled.div`
  width: 300px;
  height: 300px;
  position: fixed;
  left: 0px;
  transform: scaleX(-1);
  bottom: 0px;
  z-index: -99;
  background-image: url('./bouquet-venue.png');
  background-size: cover;
  background-position-y: 90px;
  background-repeat: no-repeat;
  background-position-x: 30px;
  @media (max-width: 980px) {
    width: 220px;
    height: 220px;
    background-position-y: 46px;
    background-position-x: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const InfoWrapper = styled.div`
  margin-right: 20px;
  max-width: 400px;
  padding: 20px;
  box-shadow: 2px 2px 16px -8px rgb(0 0 0 / 20%);
  @media (max-width: 980px) {
    margin-right: 0px;
    margin-bottom: 20px;
    max-width: inherit;
  }
`;

const StyledCheckboxGroup = styled(CheckboxGroup)`
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid gainsboro;
  display: flex;
  flex-direction: column;
  @media (max-width: 980px) {
    flex-direction: row;
  }
`;

const iconIdMap = {
  domaine: 'domaineIconLabel',
  stay: 'stayIconLabel',
  sight: 'sightIconLabel',
  airport: 'airportIconLabel',
};

export const Venue = () => {
  const [selectedVenue, selectVenue] = useState(undefined);

  const selectedPopupContent = venues.find(({ id }) => id === selectedVenue);

  const state = groupBy(venues, 'icon');

  const plainOptions = state.map((group) => {
    return langSingleton.getText(iconIdMap[group[0].icon]);
  });

  const [selectedCats, selectCat] = useState(plainOptions);
  const history = useHistory();

  return (
    <Page title={langSingleton.getText('venueTitle')}>
      <Container>
        <InfoWrapper>
          <p
            style={{ fontSize: 16, fontWeight: 200 }}
            dangerouslySetInnerHTML={{ __html: langSingleton.getText('discoverDescription') }}
          />
          <StyledCheckboxGroup
            value={selectedCats}
            onChange={(list) => {
              selectCat(list);
            }}
            style={{ flexWrap: 'wrap' }}
          >
            {plainOptions.map((opt) => (
              <Checkbox style={{ marginTop: 12, marginLeft: 0 }} value={opt}>
                {opt}
              </Checkbox>
            ))}
          </StyledCheckboxGroup>
        </InfoWrapper>
        <div style={{ flex: 1 }}>
          <MapWrapper>
            <Map
              style="mapbox://styles/alex3165/ckj8yjwoogvgx19s3wb21d77b" // eslint-disable-line
              containerStyle={{
                height: '100%',
                width: '100%',
              }}
              center={center}
              zoom={zoom}
            >
              {state
                .filter((group) =>
                  selectedCats.includes(langSingleton.getText(iconIdMap[group[0].icon])),
                )
                .sort((a, b) => (a[0].sort > b[0].sort ? -1 : 1))
                .map((group) => {
                  return (
                    <Layer
                      key={`marker-${group[0].icon}`}
                      type="symbol"
                      id={`marker-${group[0].icon}`}
                      layout={{
                        'icon-image': group[0].icon,
                        'icon-allow-overlap': true,
                      }}
                    >
                      {group.map((venue) => {
                        return (
                          <Feature
                            key={venue.id}
                            coordinates={venue.loc}
                            onMouseEnter={({ map }) => {
                              selectVenue(venue.id);
                              map.getCanvas().style.cursor = 'pointer';
                            }}
                            onMouseLeave={({ map }) => {
                              selectVenue(undefined);
                              map.getCanvas().style.cursor = '';
                            }}
                            onClick={() => {
                              if (!venue.link.includes('http')) {
                                history.push(venue.link);
                              } else {
                                window.open(venue.link, '_blank');
                              }
                            }}
                          />
                        );
                      })}
                    </Layer>
                  );
                })}

              {selectedPopupContent && (
                <Popup coordinates={selectedPopupContent.loc} anchor="bottom" offset={offset}>
                  <div style={styles.container}>
                    <img style={styles.image} src={selectedPopupContent.thumbnail} alt="Venue" />
                    <div style={styles.footer}>
                      <h3 style={{ fontSize: 15 }}>{selectedPopupContent.description}</h3>
                    </div>
                  </div>
                </Popup>
              )}
            </Map>
          </MapWrapper>
        </div>
      </Container>

      <Image />
    </Page>
  );
};
