import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.less';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Header } from './components/header';
import { Landing } from './pages/landing';
import { Attendance } from './pages/attendance';
import { Contact } from './pages/contact';
import { Information } from './pages/information';
import { Blog } from './pages/blog';
import { Venue } from './pages/venue';
import { BlogRedirect } from './pages/redirect';
import { Gift } from './pages/gift';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BlogFull } from './pages/blog-full';
import { Seating } from './pages/seating';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Header />
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/schedule">
            <Information />
          </Route>
          <Route path="/seating">
            <Seating />
          </Route>
          <Route path="/discover">
            <Venue />
          </Route>
          <Route path="/attendance">
            <Attendance />
          </Route>
          <Route path="/blog" exact>
            <Blog />
          </Route>
          <Route path="/blog/:id/:lang">
            <BlogFull />
          </Route>
          <Route path="/blog/:id" exact>
            <BlogRedirect />
          </Route>
          <Route path="/gift">
            <Gift />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
        </Switch>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
