import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { langSingleton } from '../lang';
import { Link } from 'react-router-dom';
import { Page } from '../components/Page';

const Preview = styled.div`
  max-width: 800px;
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 16px -8px rgb(0 0 0 / 20%);
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 16px -8px rgb(0 0 0 / 60%);
  }

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 0px 20px;

  @media (max-width: 980px) {
    padding: 20px;
  }
`;

const FirstColumn = styled.div`
  margin-right: 20px;
  @media (max-width: 980px) {
    margin-right: 0px;
  }
`;

const BackgroundImage = styled.div`
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: cover;
  width: 200px;
  height: 200px;
`;

const emojiMap = {
  fr: '🇫🇷',
  ru: '🇷🇺',
  en: '🇬🇧',
};

const ArticlePreview = ({ preview }) => {
  return (
    <Link to={`/blog/${preview.id}/${preview.lang}`}>
      <Preview>
        <div style={{ flex: 1 }}>
          <BackgroundImage src={preview.picture} style={{ width: '100%' }} alt="Blog preview" />
        </div>
        <ContentWrapper>
          <h3
            style={{
              fontSize: 18,
              color: '#626362',
              fontFamily: 'sans-serif',
            }}
          >
            {preview.title}
          </h3>
          <div style={{ color: '#aaa' }}>
            {langSingleton.getText('contactLanguageLabel')}: {emojiMap[preview.lang]}
          </div>
        </ContentWrapper>
      </Preview>
    </Link>
  );
};
export const Blog = () => {
  const [articlesPreviews, setArticlesPreviews] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/get-blogs?lang=${langSingleton.getLang()}`)
      .then((res) => res.json())
      .then((payload) => setArticlesPreviews(payload.data));
  }, [setArticlesPreviews]);

  const sortedArticlePreviews = articlesPreviews.sort((a, b) => (a.index > b.index ? 1 : -1));

  const half = Math.ceil(sortedArticlePreviews.length / 2);
  const firstColumnPreviews = sortedArticlePreviews.slice(0, half);
  const secondColumnPreviews = sortedArticlePreviews.slice(half, sortedArticlePreviews.length);
  return (
    <Page title={langSingleton.getText('menuBlog')}>
      <ColumnWrapper>
        <FirstColumn>
          {firstColumnPreviews.map((preview) => (
            <ArticlePreview preview={preview} />
          ))}
        </FirstColumn>
        <div>
          {secondColumnPreviews.map((preview) => (
            <ArticlePreview preview={preview} />
          ))}
        </div>
      </ColumnWrapper>
    </Page>
  );
};
