import React from 'react';
import { Page } from '../components/Page';
import { langSingleton } from '../lang';
import data from '../seating.json';

export const Seating = () => {
  return (
    <Page title={langSingleton.getText('seatingTitle')}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {Object.keys(data).map((tableN) => {
          return (
            <div
              key={tableN}
              style={{
                margin: 20,
                padding: 20,
                width: 240,
                boxShadow: '2px 2px 16px -8px rgb(0 0 0 / 20%)',
              }}
            >
              <h3 style={{ fontSize: 22 }}>Table {tableN}</h3>
              {data[tableN].map((name) => {
                return (
                  <div key={name} style={{ margin: '6px 0px', fontSize: 16 }}>
                    {name}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Page>
  );
};
