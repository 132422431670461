import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/uk';
import React from 'react';
import { Form, Button } from 'antd';
import { langSingleton } from '../lang';
import styled from 'styled-components';
import { WeddingInput } from './input';

const AttendanceButton = styled(Button)`
  height: 40px;
  width: 200px;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const Container = styled.div`
  padding: 20px;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: -80px;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const FirstBlock = styled.div`
  margin-right: 160px;
  @media (max-width: 980px) {
    margin-right: 0px;
  }
`;

export const AttendanceNameCode = ({ onFinish, onFinishFailed }) => {
  moment.locale(langSingleton.getLang());
  const dateLimit = moment().year(2022).month(2).endOf('month');

  return (
    <Container>
      <FirstBlock>
        <h4>{langSingleton.getText('closeLabel')}</h4>
        <div style={{ fontSize: 20, marginBottom: 40, fontWeight: 300 }}>
          {dateLimit.format('LL')}
        </div>
      </FirstBlock>
      <div>
        <h4>{langSingleton.getText('attendanceMainTitle')}</h4>
        <Form layout="vertical" name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item
            name="code"
            rules={[{ required: false, message: langSingleton.getText('codeRequiredMessage') }]}
          >
            <WeddingInput placeholder={langSingleton.getText('codePlaceholder')} />
          </Form.Item>
          <Form.Item>
            <AttendanceButton type="primary" htmlType="submit">
              {langSingleton.getText('attendanceMainButton')}
            </AttendanceButton>
          </Form.Item>
        </Form>
      </div>
    </Container>
  );
};
