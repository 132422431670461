import React from 'react';
import { List } from 'antd';
import styled from 'styled-components';
import { langSingleton } from '../lang';
import { Page } from '../components/Page';

const saturdaySchedule = [
  {
    datetime: '14:30 - 15:00',
    activityId: 'scheduleSaturdayActivity1',
  },
  {
    datetime: '15:00 - 16:00',
    activityId: 'scheduleSaturdayActivity2',
  },
  {
    datetime: '16:00 - 17:30',
    activityId: 'scheduleSaturdayActivity4',
  },
  {
    datetime: '17:30 - 19:45',
    activityId: 'scheduleSaturdayActivity3',
  },
  {
    datetime: '20:00 - 22:30',
    activityId: 'scheduleSaturdayActivity5',
  },
  {
    datetime: '22:30 - 04:00',
    activityId: 'scheduleSaturdayActivity6',
  },
];

const sundaySchedule = [
  {
    datetime: '09:00 - 10:30',
    activityId: 'scheduleSundayActivity1',
  },
  {
    datetime: '10:30 - 13:00',
    activityId: 'scheduleSundayActivity2',
  },
  {
    datetime: '11:00 - 15:00',
    activityId: 'scheduleSundayActivity3',
  },
];

const FirstSchedule = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 980px) {
    margin-top: 26px;
  }
`;

const SecondSchedule = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  position: relative;
  @media (max-width: 980px) {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const StyledList = styled(List)`
  width: 400px;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const StyledPage = styled(Page)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

// const Decoration = styled.div`
//   width: 260px;
//   height: 260px;
//   margin-top: 60px;
//   background-image: url('./bouquet-central.png');
//   background-size: cover;
//   background-position: center;
//   @media (max-width: 980px) {
//     margin: auto;
//   }
// `;

export const Information = () => {
  return (
    <StyledPage title={langSingleton.getText('scheduleMainTitle')}>
      <FirstSchedule>
        <div style={{ height: 460 }}>
          <h3>{langSingleton.getText('scheduleSaturdayTitle')}</h3>
          <StyledList
            dataSource={saturdaySchedule}
            renderItem={(item) => (
              <List.Item>
                <p>{item.datetime}</p>
                <p>{langSingleton.getText(item.activityId)}</p>
              </List.Item>
            )}
          />
        </div>
      </FirstSchedule>
      <SecondSchedule>
        <div style={{ height: 460 }}>
          <h3>{langSingleton.getText('scheduleSundayTitle')}</h3>
          <StyledList
            dataSource={sundaySchedule}
            renderItem={(item) => (
              <List.Item>
                <p>{item.datetime}</p>
                <p>{langSingleton.getText(item.activityId)}</p>
              </List.Item>
            )}
          />
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginTop: 100,
            }}
          >
            <img alt="Leaf decoration 1" src="./leaf-1.png" style={{ width: 50 }} />
            <img alt="Leaf double decoration 1" src="./double-leaf-1.png" style={{ width: 100 }} />
          </div>
        </div>
      </SecondSchedule>
    </StyledPage>
  );
};
