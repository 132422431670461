import React, { useState } from 'react';
import { Form, Button, Select, Checkbox } from 'antd';
import { langSingleton } from '../lang';
import styled from 'styled-components';
import { WeddingInput } from './input';

const { Option } = Select;

const itemStyle = {
  flex: 1,
};

const mapInvitationToFieldData = (invite) => {
  const [firstname, lastname] = (invite.name || ' ').split(' ');

  return {
    firstname,
    lastname,
    email: invite.email,
    diet: invite.diet,
    allergies: (invite.allergies || '').split('|').filter(Boolean),
    song: invite.song,
    stay: invite.stay,
    sundayInvite: invite.sunday === '1',
  };
};

const Wrapper = styled.div`
  display: flex;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const AttendanceButton = styled(Button)`
  height: 40px;
  margin: 8px;
`;

const ResponsiveItem = styled(Form.Item)`
  margin-right: 20px;
  @media (max-width: 980px) {
    margin-right: 0px;
  }
`;

export const AttendanceStepForm = ({
  onFinish,
  onFinishFailed,
  invitation,
  active,
  onPrev,
  last,
  first,
}) => {
  const [cantcome, setCome] = useState(false);

  if (!active) {
    return null;
  }
  const formData = mapInvitationToFieldData(invitation);

  return (
    <Form
      layout="vertical"
      name="basic"
      initialValues={formData}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={(arg) => {
        if (typeof arg.come === 'boolean') {
          setCome(arg.come);
        }
      }}
    >
      <Form.Item name="come" valuePropName="checked">
        <Checkbox>{langSingleton.getText('attendanceFormCantJoin')}</Checkbox>
      </Form.Item>
      <Wrapper>
        <ResponsiveItem
          label={langSingleton.getText('attendanceFormFirstname')}
          name="firstname"
          value={formData.firstname}
          rules={[{ required: true, message: 'Please input your First Name!' }]}
          style={{ ...itemStyle }}
        >
          <WeddingInput />
        </ResponsiveItem>

        <Form.Item
          label={langSingleton.getText('attendanceFormLastname')}
          name="lastname"
          rules={[{ required: true, message: 'Please input your Last Name!' }]}
          style={itemStyle}
        >
          <WeddingInput />
        </Form.Item>
      </Wrapper>
      {!cantcome && (
        <>
          <Form.Item
            label={langSingleton.getText('attendanceFormEmail')}
            name="email"
            rules={[{ required: false, message: 'Please input your email!' }, { type: 'email' }]}
          >
            <WeddingInput />
          </Form.Item>
          <Wrapper>
            <ResponsiveItem
              label={langSingleton.getText('attendanceFormDiet')}
              name="diet"
              rules={[
                {
                  required: true,
                  message: 'Please input a diet',
                },
              ]}
              style={itemStyle}
            >
              <Select size="large">
                <Select.Option value="veg">
                  {langSingleton.getText('attendanceFormDietOption1')}
                </Select.Option>
                <Select.Option value="everything">
                  {langSingleton.getText('attendanceFormDietOption2')}
                </Select.Option>
                <Select.Option value="vegan">
                  {langSingleton.getText('attendanceFormDietOption3')}
                </Select.Option>
                <Select.Option value="other">
                  {langSingleton.getText('attendanceFormDietOption4')}
                </Select.Option>
              </Select>
            </ResponsiveItem>

            <Form.Item
              name="allergies"
              label={langSingleton.getText('attendanceFormAllergy')}
              style={itemStyle}
            >
              <Select
                mode="multiple"
                size="large"
                placeholder={langSingleton.getText('allergiesPlaceholder')}
              >
                <Option value="nuts">
                  {langSingleton.getText('attendanceFormAllergyOption1')}
                </Option>
                <Option value="fishseafood">
                  {langSingleton.getText('attendanceFormAllergyOption2')}
                </Option>
                <Option value="lactose">
                  {langSingleton.getText('attendanceFormAllergyOption3')}
                </Option>
                <Option value="gluten">
                  {langSingleton.getText('attendanceFormAllergyOption4')}
                </Option>
                <Option value="other">
                  {langSingleton.getText('attendanceFormAllergyOption5')}
                </Option>
                <Option value="none">
                  {langSingleton.getText('attendanceFormAllergyOption6')}
                </Option>
              </Select>
            </Form.Item>
          </Wrapper>
          <Form.Item
            name="song"
            label={langSingleton.getText('attendanceFormSong')}
            rules={[{ required: true }]}
          >
            <WeddingInput />
          </Form.Item>

          {formData.sundayInvite && (
            <Form.Item name="sundayStay" valuePropName="checked">
              <Checkbox>{langSingleton.getText('attendanceFormSunday')}</Checkbox>
            </Form.Item>
          )}
          <Form.Item name="stay" valuePropName="checked">
            <Checkbox>{langSingleton.getText('attendanceFormStay')}</Checkbox>
          </Form.Item>
        </>
      )}
      <Form.Item>
        <Wrapper>
          {!first && (
            <AttendanceButton onClick={onPrev}>
              {langSingleton.getText('attendanceFormPrevious')}
            </AttendanceButton>
          )}
          <AttendanceButton type="primary" htmlType="submit">
            {last
              ? langSingleton.getText('attendanceFormComplete')
              : langSingleton.getText('attendanceFormNext')}
          </AttendanceButton>
        </Wrapper>
      </Form.Item>
    </Form>
  );
};
